import {
  UrlChangeStartedGatewayEvent,
  UrlChangeCompletedGatewayEvent,
  addGatewayListener
} from 'packages/gateway/gateway';
import { getLocationSnapshot } from 'packages/history';
import { createLink, Linkable } from 'packages/links';

export const PGM_EXCLUSION_PATTERNS: string[] = [
  '/gateway/r',
  '/account/login',
  '/account/logout',
  '/account/direct-deposit-form',
  '/account/interstitials',
  '/account/pre-onboarding'
];
export const ACP_GATEWAY_MOUNT_HISTORY_KEY = 'acp_gateway_mount_history';
const ACP_POST_LOGIN_REDIRECT_URL_KEY = 'acp_post_login_redirect_url';
/* WHEN getValue() if the getterID doesn't match the setterID
   THEN a new value is read and parse and saved to the cachedGatewayMountHistory
   AND the getterID is set to the current value of the setterID

   If the getter/setter values do match up then whatever
   is stored in the cache is returned instead of reading from storage.
*/

let cachedGatewayMountHistoryGetterID = 0;
let cachedGatewayMountHistorySetterID = 1;
/* when saving to the sessionStorage, the setterID goes up by 1. */
let cachedGatewayMountHistory: string[] = [];

const getValue = (): string[] => {
  if (cachedGatewayMountHistoryGetterID !== cachedGatewayMountHistorySetterID) {
    const raw = window.sessionStorage.getItem(ACP_GATEWAY_MOUNT_HISTORY_KEY);
    cachedGatewayMountHistoryGetterID = cachedGatewayMountHistorySetterID;
    try {
      cachedGatewayMountHistory = raw ? JSON.parse(window.atob(raw)) : [];
    } catch (error) {
      cachedGatewayMountHistory = [];
      // eslint-disable-next-line no-console
      console.error('failed to parse json: ', raw, '\nerror: ', error);
    }
  }
  return cachedGatewayMountHistory;
};

/**
 * @return string that contains a slash followed by the pathname, a querystring with back=true somewhere, and potentially the url hash
 *
 *  back=true search param is needed so that when the link is traveled to
    while going back it will cause the prev-gateway-mount history stack to reduce in size instead of grow
    if back is not present then the prev-gateway-mount history will grow **/
export const previousGatewayMountRoute = (): string => {
  const [value] = getValue().slice(-2, -1);
  if (!value) return '';
  if (value.indexOf('back=true') !== -1) return value;
  const hashIndex = value.indexOf('#');
  const hasHash = hashIndex !== -1;
  const hash = hasHash ? value.slice(hashIndex) : '';
  const valueWithoutHash = hasHash ? value.slice(0, hashIndex) : value;

  const backPart = value.indexOf('?') === -1 ? '?back=true' : '&back=true';
  return valueWithoutHash + backPart + hash;
};

/**
 * @returns a Linkable if there is a previousGatewayMountRoute otherwise will return null
 */
export const previousGatewayMountLink = (): Linkable | null => {
  const value = previousGatewayMountRoute();
  if (!value) return null;
  return createLink([value] as any)();
};

/**
 * flag used by initializePGMListener() method so that the initialization code doesn't get run twice.
 */
let hasBeenInitializedOnce = false;

/**
 *
 * @param  UrlChangeStartedGatewayEvent
 * @returns `boolean` true if the path is even remotely different enough. Otherwise after all cases are checked are checked it defaults to false
 *
 * this method will only really return true if you change either 'account' to something else or 'account/subroute' to something else.
  Routes within mfes and angular apps are ignored.
 */
const isPathDifferentEnough = ({
  from,
  to
}: UrlChangeStartedGatewayEvent): boolean => {
  const [fromPath, toPath] = [from, to].map((i) =>
    i.pathname.split('/').slice(0, 3)
  );

  const [a1, a2, a3] = fromPath;
  const [b1, b2, b3] = toPath;

  if (a1 !== b1) return true;
  if (a2 !== b2) return true;
  if (a3 !== b3) return true;
  return false;
};
/**
 * used to easily convert a url into a fake gateway event argument
 * @param url url passed in must start with /
 * @returns location type specific for UrlChangeStartedGatewayEvent['from']
 */
const urlToLocation = (url: string): UrlChangeStartedGatewayEvent['from'] => {
  const {
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search
  } = new URL(`${location.protocol}//${location.host}${url}`);
  return {
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search
  };
};
/** Used to determine if a change event has an intermediate gateway mount link in its from or to
 * @returns `boolean` `true` if from or to has gateway/r/ in from or to, if neither have then it returns `false`
 * @param pathname string from Location.pathname
 */
const pathnameHasIntermediateGatewayRoute = (pathname: string): boolean => {
  return pathname.indexOf('gateway/r/') !== -1;
};

/**
 * Initializer for Previous Gateway Mount Plugin
  Running more than once will return false.
 * @param listener not required, but can pass as argument to mock in testing. Defaults to import addGatewayListener
 * @param currentLocation not required, can pass as an argument to mock in testing. Defaults to getLocationSnapshot()
 * Will return an array with three functions.
 *
 * [
    reset initialization flag,
    stop listening for gateway url change start events,
    stop listening for gateway url change finish events
  ]
*/
export const initializePGMListener = (
  listener: typeof addGatewayListener = addGatewayListener,
  currentLocation: UrlChangeStartedGatewayEvent['from'] = getLocationSnapshot()
): false | (() => void) => {
  if (hasBeenInitializedOnce) return false;

  let inProgress = false;
  const createIntermediateGatewayRouteCallback = (
    from: UrlChangeStartedGatewayEvent['from']
  ) => (to: UrlChangeStartedGatewayEvent['to']) => {
    popFromStack(1);
    pushToStack([from, to]);
    //cleans itself up afterwards so it can't be called again
    intermediateGatewayRouteCallback = null;
  };
  let intermediateGatewayRouteCallback:
    | ((to: UrlChangeStartedGatewayEvent['to']) => void)
    | null = null;

  const urlChangeStartedHandler = (
    evt: UrlChangeStartedGatewayEvent,
    onInit = false
  ) => {
    if (inProgress && !intermediateGatewayRouteCallback) return;
    /**TODO: IF MFE nested route updates we should update the last entry in the session storage */
    if (!isPathDifferentEnough(evt)) return;
    const { from, to } = evt;
    // the evt.from is normal and to evt.is the gateway/r/. Occurs when clicking on a gateway router link
    if (
      pathnameHasIntermediateGatewayRoute(to.pathname) &&
      !pathnameHasIntermediateGatewayRoute(from.pathname)
    ) {
      if (intermediateGatewayRouteCallback === null) {
        intermediateGatewayRouteCallback = createIntermediateGatewayRouteCallback(
          from
        );
      }
      //evt.from has to be gateway/r/ and evt.to has to be normal
    } else if (
      pathnameHasIntermediateGatewayRoute(from.pathname) &&
      !pathnameHasIntermediateGatewayRoute(to.pathname) &&
      intermediateGatewayRouteCallback
    ) {
      intermediateGatewayRouteCallback(to);
    } else if (
      !pathnameHasIntermediateGatewayRoute(from.pathname) &&
      !pathnameHasIntermediateGatewayRoute(to.pathname)
    ) {
      //only push to stack if the from and to are both human readable routes
      if (!onInit) popFromStack(1);
      pushToStack([from, to]);
    }
    inProgress = true;
  };
  const urlChangeCompletedHandler = (
    evt: UrlChangeCompletedGatewayEvent,
    onInit = false
  ) => {
    if (inProgress && shouldPopFromStack(evt) && !onInit) {
      popFromStack(2);
    }
    //if navigation occurs within same mfe/app, update the value for the current entry
    if (
      !isPathDifferentEnough({
        from: urlToLocation(getValue().slice(-1)[0]),
        to: evt.location,
        type: 'urlChangeStarted'
      })
    ) {
      popFromStack(1);
      pushToStack([evt.location]);
    }
    if (shouldReset(evt)) reset();
    inProgress = false;
  };

  //need to persist current for document load. This fixes issues with address bar and refreshes.
  if (!previousGatewayMountRoute()) {
    // no previously stored value
    pushToStack([currentLocation]);
  } else {
    // emulate a change event on init to track changes over address bar and refresh based changes
    const [lastItemInArray] = getValue().slice(-1);
    urlChangeStartedHandler(
      {
        from: urlToLocation(lastItemInArray),
        to: currentLocation,
        type: 'urlChangeStarted'
      },
      true
    );
    urlChangeCompletedHandler(
      {
        activeMfes: [],
        location: currentLocation,
        type: 'urlChangeCompleted'
      },
      true
    );
  }
  hasBeenInitializedOnce = true;

  //execute each item in this array to reset the initializer and stop listening to gateway events.
  return stopPGMListener([
    listener('urlChangeStarted', urlChangeStartedHandler),
    listener('urlChangeCompleted', urlChangeCompletedHandler)
  ]);
};

/**
 * @param listeners used internally by initializePGMListener. These contain both addGatewayListener returns
 * @returns a method that once invoked will turn off the PGMListener
 */
const stopPGMListener = (listeners: Array<() => void>) => () => {
  hasBeenInitializedOnce = false;
  listeners.forEach((off) => off());
};

//removes N number of items from the history array.
const popFromStack = (howMany: number) => {
  cachedGatewayMountHistory = cachedGatewayMountHistory.slice(0, -howMany);
  setValue(cachedGatewayMountHistory);
};

/** filter utilized by pushToStack method which sends the values to setValue method
 * @returns `true` if item should be included and `false` if item should not be included
 * @param UrlChangeStartedGatewayEvent `['from']`
 *
 * this is used in a Array.filter method
 */
const filterGatewayMountAdditions = ({
  pathname
}: UrlChangeStartedGatewayEvent['from']): boolean => {
  return !PGM_EXCLUSION_PATTERNS.find(
    (pattern) => pathname.indexOf(pattern) !== -1
  );
};

/**
 * filters input values and joins them with current values before sending to setValue method
 * @param values array of location snapshots
 *
 * filters out gateway/r values from getting set
 * only saves last 100 values
 */
const pushToStack = (values: Array<UrlChangeStartedGatewayEvent['from']>) => {
  const [{ pathname }] = values;
  const [firstFromStore] = getValue().slice(-1);

  const transformedValues = (firstFromStore &&
  firstFromStore.indexOf(pathname) !== -1
    ? values.slice(1)
    : values
  )
    .filter(filterGatewayMountAdditions) //ignore gateway/r values getting errantly stored during react --> angular transitions
    .map((value) => value.pathname + value.search + value.hash);

  cachedGatewayMountHistory = [...getValue(), ...transformedValues].slice(-100);
  setValue(cachedGatewayMountHistory);
};
/**
 * puts the history array into session storage
 * @param value must be same type as the history array
 */
const setValue = (value: typeof cachedGatewayMountHistory) => {
  cachedGatewayMountHistorySetterID++;
  window.sessionStorage.setItem(
    ACP_GATEWAY_MOUNT_HISTORY_KEY,
    window.btoa(JSON.stringify(value.slice(-100)))
  );
};

const shouldPopFromStack = (evt: UrlChangeCompletedGatewayEvent): boolean => {
  return evt.location.search.indexOf('back=true') !== -1;
};
/**
 * @returns `boolean`
 * @param UrlChangeCompletedGatewayEvent
 * used to determine whether or not storage system should be reset in the event of logout/login.
 * Should not be reset if currently deep linking towards something
 */
const shouldReset = ({ location }: UrlChangeCompletedGatewayEvent): boolean => {
  return (
    location.pathname.indexOf('account/login') !== -1 &&
    !window.sessionStorage.getItem(ACP_POST_LOGIN_REDIRECT_URL_KEY)
  );
};

/** Will clear memoization values and cache, as well as delete the ACP_GATEWAY_MOUNT_HISTORY_KEY
 * this SHOULD not be utilized externally but is exported here so that you can manipulate the cache during testing */
export const reset = () => {
  //used to clear memo for testing mainly
  cachedGatewayMountHistoryGetterID = 0;
  cachedGatewayMountHistorySetterID = 1;
  cachedGatewayMountHistory = [];
  window.sessionStorage.removeItem
    ? window.sessionStorage.removeItem(ACP_GATEWAY_MOUNT_HISTORY_KEY)
    : delete window.sessionStorage[ACP_GATEWAY_MOUNT_HISTORY_KEY];
};
